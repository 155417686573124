$primary: #fcde67;
$secondary: #5bccf5;
$dark: #030e12;
$light: #b1c3c6;
$success: #1b112b;



.backgroundd {
    /* The image used */
    background-image: url("./images/angryimg.png");

    /* Full height */
    height: 100%; 

    /* Center and scale the image nicely */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: 100%;
    flex: 1 0 auto;

    
}

.navbar.transparent.navbar-inverse .navbar-inner {
    background: rgba(0,0,0,0.4);
 }













@import "~bootstrap/scss/bootstrap.scss"

